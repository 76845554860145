<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-drag-variant
      </v-icon> App Users Grouping
    </h2>
    <br>
    <v-dialog
      v-model="showModal"
      max-width="400"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#000"
          dark
        >
          <v-toolbar-title>
            Edit Name
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Node Name"
            hint="Name for your reference - 'Plumber'."
            type="text"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#aaa"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#000"
              text
              @click="submit()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-stepper
      v-model="appUsersGrouping"
      shaped
      class="my-stepper-style"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="appUsersGrouping > 1"
          step="1"
          color="#000"
        >
          Create Structure
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          step="2"
          color="#000"
        >
          Group App Users
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <v-card
          shaped
          color="#ECEFF1"
        >
          <br>
          <base-tree-view
            :value="treeData"
            draggable
            droppable
            class="my-tree-view-style"
            @edit-name="editNodeName"
            @delete-node="deleteNodeName"
          />
          <br>
          <v-card-actions>
            <v-btn
              color="#aaa"
              @click="cancel()"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#000"
              @click="next()"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <group-app-users v-if="appUsersGrouping >= 2" />
      </v-stepper-content>
    </v-stepper>
    <br>
    <centre-spinner
      :loading="loading"
    />
    <div v-scroll="onScroll">
      <v-btn
        v-if="isVisible"
        fab
        dark
        fixed
        bottom
        right
        x-small
        class="scroll-style"
        color="#455A64"
        @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
    <delete-dialog
      v-if="showDelete"
      :title="'Delete Group'"
      :message="'This action cannot be undone. It will delete the selected group and all its app users.'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import GroupAppUsers from 'src/views/dashboard/appusers/appusersgrouping/GroupAppUsers';

  export default {
    name: 'CreateAppUsersStructure',
    components: {
      'centre-spinner': spinner,
      'delete-dialog': DeleteDialog,
      'group-app-users': GroupAppUsers,
    },
    data () {
      return {
        treeData: [{ text: 'All App Users', children: [{ text: 'App Users-Workers' }, { text: 'App Users-Contractors' }] }],
        showModal: false,
        showDelete: false,
        deleteGroup: {},
        name: '',
        selectedNode: '',
        appUsersGrouping: 1,
        loading: false,
        isVisible: false,
      };
    },
    computed: {
      savedAppUsersStructure () {
        return this.$store.getters['appusers/getSavedAppUsersStructure'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('appusers/fetchSavedAppUsersStructure');
      this.isStructureAvailable();
      this.loading = false;
    },
    methods: {
      onScroll () {
        this.isVisible = window.scrollY > 50;
      },
      toTop () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      editNodeName (value) {
        if (value.id === undefined && value.text !== 'All App Users') {
          this.showModal = true;
          this.selectedNode = value;
          this.name = value.text;
          return;
        }
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to edit this item.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      },
      deleteNodeName ({ tree, node, path }) {
        if (node.id === undefined && node.text !== 'All App Users') {
          this.showDelete = true;
          this.deleteGroup = {
            tree: tree,
            node: node,
            path: path,
          };
          return;
        }
         this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to delete this item.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
      },
      deleteConfirmed () {
        const tree = this.deleteGroup.tree;
        tree.removeNodeByPath(this.deleteGroup.path);
        this.showDelete = false;
        this.deleteGroup = {};
      },
      submit () {
        if (this.name === 'All App Users') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Already a node with "All App Users" exist.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.selectedNode.text = this.name;
        this.showModal = false;
      },
      async next () {
        if (this.treeData.length > 1 || this.treeData[0].text !== 'All App Users') {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please add the newly created nodes under "All App Users".',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        await this.$store.dispatch('appusers/createAppUsersStructure', this.treeData);
        this.appUsersGrouping = 2;
      },
      isStructureAvailable () {
        if (Object.keys(this.savedAppUsersStructure).length <= 0) {
          return this.treeData;
        }
        this.treeData = this.savedAppUsersStructure.group;
      },
      cancel () {
        this.$router.push({ name: 'App Users' });
      },
    },
  };
</script>
<style scoped>
.my-stepper-style {
  background-color: #fff !important;
  margin-right: 30px;
  margin-left: 30px;
  border: 1px solid #ccc !important;
  border-radius: 0px !important;
}
.title-style {
  color: #37474F;
  margin-left: 30px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tree-view-style {
  margin-left: 30px;
}
.scroll-style {
  margin-bottom: 80px;
  margin-right: 10px;
}
</style>
